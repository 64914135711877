import { action } from 'typesafe-actions';
import { User2FAType } from '../../library/enums';

import {
  SET_AUTH_CODE_TOKEN,
  RESET_AUTHENTICATION_STATE,
  SET_DEVICE_DETAILS,
  SET_FINGERPRINT_DETAILS,
  SET_REDIRECT_FROM,
  SET_REDIRECT_TO,
  SET_IS_LOADING,
  SET_VERIFY_CHANNEL,
  SET_AUTH_STATE,
  SET_REGISTRATION_TOKEN,
} from './constants';
import { AuthState } from './reducer';

export const setAuthCodeToken = (token: string) => action(SET_AUTH_CODE_TOKEN, token);
export const setRegistrationToken = (token: string) => action(SET_REGISTRATION_TOKEN, token);
export const resetAuth = () => action(RESET_AUTHENTICATION_STATE);
export const setDeviceDetails = (deviceDetails: { [key: string]: any }) => action(SET_DEVICE_DETAILS, deviceDetails);
export const setFingerprintDetails = (fingerprintDetails: { [key: string]: any }) =>
  action(SET_FINGERPRINT_DETAILS, fingerprintDetails);
export const setRedirectFrom = (redirectFrom: 'POWER' | 'MOBILE' | 'UNIFIED' | undefined) =>
  action(SET_REDIRECT_FROM, redirectFrom);
export const setRedirectTo = (redirectTo: string) => action(SET_REDIRECT_TO, redirectTo);
export const setIsLoading = (isLoading: boolean) => action(SET_IS_LOADING, isLoading);
export const setVerifyChannel = (verifyChannel: User2FAType) => action(SET_VERIFY_CHANNEL, verifyChannel);
export const setAuth = (authState: Partial<AuthState>) => action(SET_AUTH_STATE, authState);
